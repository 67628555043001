import '../../atoms/button/Button';
import '../../atoms/image/Image';
/* eslint-disable import/no-duplicates */
import '../../atoms/carousel-indicators/CarouselIndicators';
import CarouselIndicators, {
    CarouselIndicatorsEvents
} from '../../atoms/carousel-indicators/CarouselIndicators';
/* eslint-enable import/no-duplicates */
import '../../molecules/slides-carousel/SlidesCarousel';
import type { ImageProps } from '../../atoms/image/Image';
import type SlidesCarousel from '../../molecules/slides-carousel/SlidesCarousel';
import './ContentCarousel.pcss';

type CarouselSlide = {
    title: string;
    content: string;
    image: ImageProps;
};

export type ContentCarouselProps = {
    slides: CarouselSlide[];
};

const intersectionThreshold: number[] = Array.from(Array(50).keys()).map((value) => value * 0.02);

export default class ContentCarousel extends HTMLElement {
    #imagesCarousel: SlidesCarousel | null = null;

    #carouselIndicators: CarouselIndicators | null = null;

    #contentSlides: HTMLElement[] = [];

    #prevButtons: HTMLButtonElement[] = [];

    #nextButtons: HTMLButtonElement[] = [];

    #intersectionObserver: IntersectionObserver | undefined;

    #imagesChangeHandler = (): void => {
        if (!this.#imagesCarousel) {
            return;
        }
        const slide = this.#imagesCarousel.slide;

        this.#contentSlides.forEach((indicator, index) => {
            indicator.toggleAttribute('active', index === slide);
        });

        if (this.#carouselIndicators) {
            this.#carouselIndicators.activeIndex = slide;
        }

        this.#imagesCarousel?.scrollToSlide(slide);
    };

    #prevClickHandler = (): void => {
        this.#imagesCarousel?.prev();
        this.#imagesChangeHandler();
    };

    #nextClickHandler = (): void => {
        this.#imagesCarousel?.next();
        this.#imagesChangeHandler();
    };

    protected connectedCallback(): void {
        this.#connectElements();

        this.#imagesCarousel?.addEventListener('changed', this.#imagesChangeHandler);
        this.#prevButtons.forEach((button) => {
            button.addEventListener('click', this.#prevClickHandler);
        });
        this.#nextButtons.forEach((button) => {
            button.addEventListener('click', this.#nextClickHandler);
        });

        this.addEventListener(CarouselIndicatorsEvents.SELECTED_INDICATOR, (event) => {
            this.#imagesCarousel?.scrollToSlide((event as CustomEvent).detail);
            this.#imagesChangeHandler();
        });

        this.#attachIntersectionObserver();
    }

    protected disconnectedCallback(): void {
        this.#imagesCarousel?.removeEventListener('changed', this.#imagesChangeHandler);
        this.#prevButtons.forEach((button) => {
            button.removeEventListener('click', this.#prevClickHandler);
        });
        this.#nextButtons.forEach((button) => {
            button.removeEventListener('click', this.#nextClickHandler);
        });

        this.removeEventListener(CarouselIndicatorsEvents.SELECTED_INDICATOR, (event) => {
            this.#imagesCarousel?.scrollToSlide((event as CustomEvent).detail);
            this.#imagesChangeHandler();
        });
    }

    #connectElements(): void {
        this.#imagesCarousel = this.querySelector('nh-slides-carousel[data-role="images"]');

        this.#carouselIndicators = this.querySelector('nh-carousel-indicators');

        this.#contentSlides = Array.from(this.querySelectorAll('[data-role="slide-content"] > li'));

        this.#prevButtons = Array.from(this.querySelectorAll('button[data-role="prev"]'));

        this.#nextButtons = Array.from(this.querySelectorAll('button[data-role="next"]'));

        this.#intersectionObserver?.disconnect();
        this.#intersectionObserver = undefined;
    }

    #attachIntersectionObserver(): void {
        if (!this.#imagesCarousel) return;

        const callback = (entries: IntersectionObserverEntry[]): void => {
            entries.forEach((entry) => {
                const slide = entry.target as HTMLElement;
                slide.style.opacity = `${entry.intersectionRatio}`;

                const image: HTMLElement | null = slide?.querySelector('img');

                if (!image) return;
                const scale = entry.intersectionRatio * 0.2;
                image.style.transform = `scale(${0.8 + scale})`;
            });
        };

        const options = {
            root: this.querySelector('[data-role="images"]') as HTMLElement,
            rootMargin: '0px',
            threshold: intersectionThreshold
        };

        this.#intersectionObserver = new IntersectionObserver(callback, options);
        this.#imagesCarousel.querySelectorAll('[data-role="image-slide"]').forEach((slide) => {
            this.#intersectionObserver?.observe(slide);
        });
    }
}

if (!customElements.get('nh-content-carousel')) {
    customElements.define('nh-content-carousel', ContentCarousel);
}
